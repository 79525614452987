.border--solid {
  border-style: solid;
}

.border--dashed {
  border-style: dashed;
}

.border--dotted {
  border-style: dotted;
}
