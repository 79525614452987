@import 'node_modules/tachyons-sass/scss/normalize';

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: unset;
}

a,
button,
input[type='button'] {
  cursor: pointer;
}

button {
  font-family: $sans-serif;
}

button:focus {
  outline: none;
}

p {
  margin: 0;
}
