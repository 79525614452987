.border {
  border-color: map-get($map: $colors, $key: 'gray-light');
  border-style: solid;
  border-width: 1px;
}

.border--top {
  border-color: map-get($map: $colors, $key: 'gray-light');
  border-top-style: solid;
  border-top-width: 1px;
}

.border--right {
  border-color: map-get($map: $colors, $key: 'gray-light');
  border-right-style: solid;
  border-right-width: 1px;
}

.border--bottom {
  border-color: map-get($map: $colors, $key: 'gray-light');
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.border--left {
  border-color: map-get($map: $colors, $key: 'gray-light');
  border-left-style: solid;
  border-left-width: 1px;
}

.border--none {
  border-color: map-get($map: $colors, $key: 'transparent');
  border-style: none;
  border-width: 0;
}
