$orange: #E87959;
$navy: #31324C;
$sky: #D0EAF5;
$green: #74948A;
$white: #FFFFFF;
$black: #000000;
$transparent: transparent;
$gray-dark:#373737;
$gray: #9B9B9B;
$gray-light:  #e4e4e4;
$off-white: #F3F3F3;
$blue: #4A90E2;
$red-light: #eccece;
$red: #DC3545;
$red-dark: #7c3131;

$danger: #DC3545;
$warning: #CCAB52;
$success: #008000;

$colors: (
  // brand colors
  'orange': $orange,
  'navy': $navy,
  'sky': $sky,
  'green': $green,

  // base colors
  'white': $white,
  'black': $black,
  'transparent': $transparent,

  // core colors
  'gray-dark': $gray-dark,
  'gray': $gray,
  'gray-light': $gray-light,
  'off-white': $off-white,
  'blue': $blue,

  'red-light': $red-light,
  'red': $red,
  'red-dark': $red-dark,

  // status indicators
  danger: $danger,
  warning: $warning,
  success: $success
);
