.icon {
  &--xs {
    font-size: map-get($map: $font-sizes, $key: 'f6');
  }

  &--sm {
    font-size: map-get($map: $font-sizes, $key: 'f4');
  }

  &--md {
    font-size: map-get($map: $font-sizes, $key: 'f3');
  }

  &--lg {
    font-size: map-get($map: $font-sizes, $key: 'f2');
  }

  &--inline {
    line-height: inherit;
  }
}
