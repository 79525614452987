.f1 {
  font-size: map-get($map: $font-sizes, $key: f1);
}

.f2 {
  font-size: map-get($map: $font-sizes, $key: f2);
}

.f3 {
  font-size: map-get($map: $font-sizes, $key: f3);
}

.f4 {
  font-size: map-get($map: $font-sizes, $key: f4);
}

.f5 {
  font-size: map-get($map: $font-sizes, $key: f5);
}

.f6 {
  font-size: map-get($map: $font-sizes, $key: f6);
}
