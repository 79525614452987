.ws-normal {
  white-space: normal;
}

.no-wrap {
  white-space: nowrap;
}

.pre {
  white-space: pre;
}

.pre-wrap {
  white-space: pre-wrap;
}
