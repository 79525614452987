.table {
  width: 100%;

  &.table--borders {
    border-collapse: collapse;
    border-spacing: 0;

    tbody {
      border-left: 1px solid map-get($map: $colors, $key: 'gray-light');
      border-right: 1px solid map-get($map: $colors, $key: 'gray-light');
      border-bottom: 1px solid map-get($map: $colors, $key: 'gray-light');
    }

    tr,
    td,
    th {
      border-left: 1px solid map-get($map: $colors, $key: 'gray-light');
      border-right: 1px solid map-get($map: $colors, $key: 'gray-light');
    }

    th {
      border-bottom: 1px solid map-get($map: $colors, $key: 'gray-light');
    }
  }
}
