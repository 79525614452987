@import 'node_modules/tachyons-sass/scss/variables';

/*
  Utility partials via tachyons-sass. Only add these as needed.
  For the full list, see https://github.com/tachyons-css/tachyons-sass/tree/master/scss.
*/
@import 'node_modules/tachyons-sass/scss/border-radius';
@import 'node_modules/tachyons-sass/scss/box-shadow';
@import 'node_modules/tachyons-sass/scss/coordinates';
@import 'node_modules/tachyons-sass/scss/flexbox'; // import flexbox before display
@import 'node_modules/tachyons-sass/scss/display';
@import 'node_modules/tachyons-sass/scss/font-style';
@import 'node_modules/tachyons-sass/scss/font-weight';
@import 'node_modules/tachyons-sass/scss/heights';
@import 'node_modules/tachyons-sass/scss/hovers';
@import 'node_modules/tachyons-sass/scss/line-height';
@import 'node_modules/tachyons-sass/scss/links';
@import 'node_modules/tachyons-sass/scss/max-widths';
@import 'node_modules/tachyons-sass/scss/opacity';
@import 'node_modules/tachyons-sass/scss/overflow';
@import 'node_modules/tachyons-sass/scss/position';
@import 'node_modules/tachyons-sass/scss/spacing';
@import 'node_modules/tachyons-sass/scss/text-align';
@import 'node_modules/tachyons-sass/scss/text-decoration';
@import 'node_modules/tachyons-sass/scss/text-transform';
@import 'node_modules/tachyons-sass/scss/utilities';
@import 'node_modules/tachyons-sass/scss/widths';
